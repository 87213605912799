import $axios from '@/utils/request'
import $axiosVideo from '@/utils/requestvideo'

// 上传文件
export function uploadCommon (data) {
  const url = '/file/uploadCommon'
  return $axiosVideo.fVideoPost(url, data)
}

// 上传文件
export function uploadCheckCommon (data) {
  const url = '/file/uploadCheckCommon'
  return $axiosVideo.fVideoGet(url, data)
}

// 上传图片
export function uploadMaps (data) {
  const url = '/common/file/uploadMaps'
  return $axios.fInfoPost(url, data)
}

// 获取服务器当前时间
export function serverTime (data) {
  const url = '/other/now'
  return $axios.fGet(url, data)
}
