<template>
  <div :class="{fullscreen:fullscreen}" class="tinymce-container" :style="{width:containerWidth}">
    <!-- tinymceId：{{tinymceId}} -->
    <textarea :id="tinymceId" class="tinymce-textarea" />
  </div>
</template>

<script>
import plugins from './plugins'
import toolbar from './toolbar'
import { uploadMaps,uploadCommon,uploadCheckCommon } from '@/api/public'
import SparkMD5 from "spark-md5"

export default {
  name: 'Tinymce',
  props: {
    id: {
      type: String,
      default: function() {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    value: {
      type: String,
      default: ''
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table'
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto'
    }
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: { // 富文本编辑器语言
        'en': 'en',
        'zh': 'zh_CN',
        'es': 'es_MX',
        'ja': 'ja'
      }
    }
  },
  computed: {
    containerWidth() {
      const width = this.width
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        return `${width}px`
      }
      return width
    }
  },
  watch: {
    value(val) {
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || ''))
      }
    }
  },
  mounted() {
    this.initTinymce()
  },
  activated() {
    if (window.tinymce) {
      this.initTinymce()
    }
  },
  deactivated() {
    this.destroyTinymce()
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    async uploadCommon(callback,file) {
      const formData = new FormData();
      formData.append('file', file)
      formData.append('type', '文档')

      const res = await uploadCommon(formData)
      if(res.code === 0){
        if(res.data !== "") {
          callback(res.data.path, { text:res.data.title, title: res.data.title })
        }
      }
    },
    async uploadCheckCommon(callback,file) {
      const res = await uploadCheckCommon({
        hash: this.hash
      })
      if(res.code === 0){
        if(res.data === "") {
          this.uploadCommon(callback,file)
        }else {
          callback(res.data.path, { text:res.data.title, title: res.data.title })
        }
      }
    },
    initTinymce() {
      const _this = this
      window.tinymce.init({
        selector: `#${this.tinymceId}`,
        language: 'zh_CN',
        height: this.height,
        body_class: 'panel-body', // 给iframe body标签添加class名
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: this.menubar, // 顶部操作状态栏
        plugins: plugins,
        end_container_on_empty_block: true, // 是否在末尾添加空div
        code_dialog_height: 450,
        code_dialog_width: 1000,
        quickbars_image_toolbar: "alignleft aligncenter alignright quicklink |  imageoptions", // 选中图片时的快捷栏
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
        default_link_target: '_blank', // 点击链接是否跳转新页面
        link_title: false, // 链接标题
        media_live_embeds: true,
        branding: false,
        nonbreaking_force_tab: true, // 插入非间断空格;需要非间断空间插件
        powerpaste_word_import: 'propmt', // 参数可以是propmt, merge, clear
        powerpaste_html_import: 'propmt', // propmt, merge, clear
        powerpaste_allow_local_images: true, // 复制粘贴图片是否允许本地图片
        paste_data_images: true, // 允许用户粘贴图片
        file_picker_callback: function(callback, value, meta) {
          console.log(meta)
          // // 要先模拟出一个input用于上传本地文件
          var input = document.createElement('input')
          input.setAttribute('type', 'file')
          input.setAttribute('accept', '.doc,.docx,.zip,.pdf,.rar,.png,.jpg,.xlsx,.xls,.ppt,.pptx')
          input.click()
          input.onchange = function() {
            var file = this.files[0]

            let arr = file.name.split('.')
            if (arr && arr.length > 1) {
              let suffixName = arr[arr.length - 1].toLowerCase()
              if (suffixName !== 'png' &&suffixName !== 'jpg' &&suffixName !== 'xlsx' &&suffixName !== 'xls'&&suffixName !== 'ppt' &&suffixName !== 'pptx'  &&suffixName !== 'doc' && suffixName !== 'docx'&& suffixName !== 'zip'&& suffixName !== 'pdf'&& suffixName !== 'rar') {
                this.$message.error('文件类型错误，请重新上传！')
                return false
              }
            }
            const isLt100M = file.size / 1024 / 1024 < 100;
            if (!isLt100M) {
              window.$msg('上传文件大小不能超过100MB!',2)
              return;
            }

            let fileRaw = file

            let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
            let fileReader = new FileReader()
            let spark = new SparkMD5()
            fileReader.readAsBinaryString(blobSlice.call(fileRaw, 0, 1024*1024))

            fileReader.onerror = function () {
              console.warn('FileReader error.')
            }

            fileReader.onload = function (e) {
              spark.appendBinary(e.target.result)
              _this.hash = spark.end()
              console.log(_this.hash)
              _this.uploadCheckCommon(callback,file)
            }
          }
        },
        init_instance_callback: editor => {
          if (_this.value) {
            editor.setContent(_this.value)
          }
          _this.hasInit = true
          editor.on('NodeChange Change KeyUp SetContent', () => {
            this.hasChange = true
            this.$emit('input', editor.getContent())
          })
        },
        setup(editor) {
          editor.on('FullscreenStateChanged', (e) => {
            _this.fullscreen = e.state
          })
        },
        convert_urls: false,
        images_upload_handler(blobInfo, success, failure) { // 图片上传
          // progress(0);
          const formData = new FormData();
          formData.append('files', blobInfo.blob());
          uploadMaps(formData).then(res => {
            if (res.state === 'success') {
              success(res.body[0].fileUrl);
              // progress(100);
            }
          })
        }
      })
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }
      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent()
    },
    imageSuccessCBK(arr) {
      arr.forEach(v => window.tinymce.get(this.tinymceId).insertContent(`<img class="wscnph" src="${v.url}" >`))
    }
  }
}
</script>

<style lang="scss" scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
}

.tinymce-container {
  ::v-deep {
    .mce-fullscreen {
      z-index: 11000;
    }
  }
}

.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}

.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  /*z-index: 2005;*/
}

.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}

.editor-upload-btn {
  display: inline-block;
}
</style>
